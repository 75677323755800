/* triangle top right shape  (https://css-tricks.com/the-shapes-of-css/) */
/*.player-win {*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-top: 2rem solid gold;*/
/*    border-left: 2rem solid transparent;*/
/*}*/

/*.player-win {*/
/*  position: relative;*/
/*  -webkit-box-shadow: 5px 5px 5px rgba(0,0,0,0.8);*/
/*  -moz-box-shadow: 5px 5px 5px rgba(0,0,0,0.8);*/
/*  box-shadow: 5px 5px 5px rgba(0,0,0,0.8);*/
/*}*/

/*.player-win:before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: 0%;*/
/*  left: 0%;*/
/*  width: 0px;*/
/*  height: 0px;*/
/*  border-bottom: 70px solid #eee;*/
/*  border-left: 70px solid #272822; !*Set to background color, not transparent!*!*/
/*  -webkit-box-shadow: 7px 7px 7px rgba(0,0,0,0.3);*/
/*  -moz-box-shadow: 7px 7px 7px rgba(0,0,0,0.3);*/
/*  box-shadow: 7px 7px 7px rgba(0,0,0,0.3);*/
/*}*/

/* https://stackoverflow.com/a/18532104/1343120 */
.player-win {
    /*width: 10em;*/
    /*height: 6em;*/
    /* border: 4px solid #ccc; */
    background-color: #fff;
    position: relative;
}

.player-win::before,
.player-win::after {
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
}

/*.player-win::before {*/
/*    border-width: 1.5em;*/
/*    border-right-color: #ccc;*/
/*    border-top-color: #ccc;*/
/*}*/

.player-win::after {
    /*border-radius: 0.4em;*/
    /*border-width: 1.35em;*/
    content: "★";
    font-size: 150%;
    line-height: 1.6ex;
    color: gold;
    text-shadow: 0 0 2px black;
    top: -1px;
    right: -2px;
}
.username-title {
    text-align: right;
}

.password-title {
    text-align: right;
}

.login-error-message {
    text-align: right;
    color: darkred;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print {
  #root:has(~ .modal > .modal-fullscreen) {
    display: none;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.clickMe {
  color: #007bff;
}

.navbar-icon {
  vertical-align: top;
  margin: -6px 0 -6px 0px;
}
.navbar-title {
  display: inline-block;
  vertical-align: baseline;
  height: 73px;
  padding-left: 0.2em;
}
.navbar-name {
  font-size: 56px;
  vertical-align: baseline;
  line-height: 50px;
  color: #666;
}
.navbar-company {
  font-size: 29px;
  vertical-align: middle;
  line-height: 27px;
  color: #ddd;
  padding-left: 4px;
}
@media (max-width: 768px) {
  .navbar-icon {
    height: 48px;
  }
  .navbar-title {
    height: 36px;
  }
  .navbar-name {
    font-size: 22px;
    line-height: 25px;
  }
  .navbar-company {
    font-size: 14px;
    line-height: 13px;
    padding-left: 1px;
  }
  .navbar-text {
    font-size: 70%;
  }
}
.bg-subtle {
  background-color: #e8e8e8;
  border: 1px solid #e0e0e0;
}
.bg-straw {
  background-color: rgba(var(--bs-warning-rgb), 0.1)!important;
}
.minTab {
  min-width: 3em!important;
}
.auditTarget .badge {
  margin: 0.1rem !important;
  max-width: 100%;
  white-space: normal;
  text-align: left;
  line-height: normal;
}
.nav-tabs .nav-link {
  min-width: 5em;
  font-size: 93%;
  max-width: 12em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  padding: 6px 12px;
}
.nav-tabs .btn-close {
  font-size: 80%;
  margin: 1px -8px 0 0;
}
.toast-container {
  z-index: 2000;
  position: fixed!important;
  top: 0.5rem;
  right: 0.5rem;
}
.ico-allow {
  text-indent: -1.2rem;
  margin-left: 1rem;
}
.tt-btns > * {
  margin-right: 1rem;
}
.toast {
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 60%);
  border: 0;
}
.toast .btn-close {
  position: absolute;
  right: 1rem;
}
/* Table overflow fix - absolute positioned UI elements within tables are unusable without this */
/* See: https://github.com/jbetancur/react-data-table-component/issues/965 */
.ovfix div {
  overflow: visible!important;
}

/* Fix rendering issue with TypeAhead "remove" botton */
.rbt .rbt-token .rbt-token-remove-button {
  border-radius: 0.25rem;
  width: 25px;
  top: 0;
  background-color: #007bff;
  color: white;
  border-style: hidden;
}
.rbt .rbt-token-removeable {
  padding-right: 28px;
}
/* Make TypeAhead "close" button styling less incongruous */
.rbt .rbt-aux .rbt-close {
    border: none;
    background: inherit;
    font-size: 140%;
    font-weight: bold;
    color: var(--bs-primary);
    margin-top: 0px;
}

.bodyscroll .rdt_TableBody {
  overflow-y: auto;
  height: 60vh;
}
/* --- */
.btn-link {
  text-decoration: none;
}
/* .nav-tabs .tabType {
  font-size: 60%;
  position: absolute;
  color: black;
  margin-top: -8px;
} */
.tabIcon {
  margin: -1px 0 4px -6px;
  height: 1.1em;
  width: 1.1em;
}
.lessPad button {
  padding: 0.3rem 0.5rem;
}
.big-check input[type=checkbox] {
  height: 1.5rem;
  width: 1.5rem;
}
.right-menu {
  position: sticky;
  top: 0;
  padding: 1rem 0.6rem 1rem 0;
  width: 12rem;
  float: right;
}
/*
 * Note that links don't seem to be treated as though they have words so make them
 * break anywhere
 */

.table-column-wrap-link {
  word-wrap: anywhere;
}

.table-column-wrap {
  word-wrap: break-word;
}

/* allow "table-layout: auto" on a react-bootstrap-table-next table */
.react-bootstrap-table table.layout-auto {
  table-layout: auto;
}

/* Make sure it is on top */
.react-datepicker-popper {
  z-index: 9 !important;
}

.tournSummary .label {
  color: #888;
  /* background: #f8f8f8;
  border-right: 4px solid #eee; */
}
.tournSummary .row {
  margin: 4px 0;
}

.rePair .unPaired > * {
  height: 100%;
}
.rePair .rdt_TableCell > div {
  height: 100%;
  width: 100%;
}
.rePair .rdt_TableCell > div > div {
  height: 100%;
  width: 100%;
}
.rePair .empty {
  height: 100%;
  align-items: center;
  display: flex;
  color: var(--bs-gray-500);
}
.PayUsingStripe {
  background: #007bff;
  width: 24em;
  padding: 1ex;
  border-radius: 5px;
  line-height: 1.2;
}
.PayUsingStripe .input-group, .PayUsingStripe .input-group-text, .PayUsingStripe .form-control {
  line-height: 1.2;
  background: inherit;
  border: 0;
}
.PayUsingStripe .input-group {
  border-bottom: 1px solid #2d92ff;
}
.PayUsingStripe .form-control {
  color: #ffffff;
}
.PayUsingStripe .input-group-text {
  background-color: inherit;
  padding: 0;
  border: 0;
  width: 4em;
  color: #aab7c4;
}
.PayUsingStripe .btn-primary {
  background-color: #aab7c4;
  width: 100%;
}
.PayUsingStripe .ErrorMessage {
  color: #ffa9a9;
  line-height: 2;
}